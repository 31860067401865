import React, { useContext } from "react";

const DEFAULT_API_URL = process.env.REACT_APP_API_URL;
const CATSBEY_REFERRER_HEADER = "X-Catsbey-Referrer";
const CATSBEY_ADDRESS_HEADER = "X-Catsbey-Address";

const CATSBEY_ACCOUNT_STORAGE_KEY = "catsbey";

type ApiOnAccount = (account: ApiAccount) => void;
type ApiOnError = (error: Error) => void;

export type ApiAccount = {
    referrer?: string;
    addresses: string[];
};

export type ApiPool = {
    name: string,
    account: string,
    ticketPrice: number,
    ticketCount: number,
    bidCount: number,
};

export enum KnownApiErrorCode {
    ServiceError = "SERVICE_ERROR",
}

export type ApiErrorCode = KnownApiErrorCode | string;

type ApiEventHandler = {
    account?: ApiOnAccount;
    error?: ApiOnError;
};

export class Api {
    private readonly url: string;
    private eventHandlers: ApiEventHandler[] = [];
    account: ApiAccount;
    isAuth = false;

    constructor(url?: string) {
        this.url = url ?? DEFAULT_API_URL ?? "http://localhost:8000";
        this.account = { addresses: [] };
        let stored = localStorage.getItem(CATSBEY_ACCOUNT_STORAGE_KEY);
        if (stored) {
            try {
                this.account = JSON.parse(stored);
            } catch {
            }
        }
        (async () => {
            await this.refreshAccount();
        })();
    }

    on(handler: { account: ApiOnAccount }) {
        this.eventHandlers.push(handler);
        return handler;
    }

    remove(handler: ApiEventHandler) {
        this.eventHandlers = this.eventHandlers.filter(x => x !== handler);
    }

    private updateReferrer(referrer: string) {
        this.updateAccount({ ...this.account, referrer });
    }

    private updateAddresses(addresses: string[]) {
        this.updateAccount({ ...this.account, addresses });
    }

    private fireAccount(account: ApiAccount) {
        for (const handler of this.eventHandlers) {
            handler.account?.(account);
        }
    }

    private fireError(err: Error) {
        for (const handler of this.eventHandlers) {
            handler.error?.(err);
        }
    }

    private updateAccount(account: ApiAccount) {
        this.account = account;
        localStorage.setItem(CATSBEY_ACCOUNT_STORAGE_KEY, JSON.stringify(this.account));
        this.fireAccount(this.account);
    }


    private async refreshAccount() {
        try {
            this.fireAccount(this.account);
        } catch (err) {
            this.fireError(new Error(`Failed to load account information: ${err}`));
        }
    }

    private async queryApi(query: string, vars: object) {
        let response, body;
        try {
            const headers = new Headers({
                "Content-Type": "application/json",
            });
            if (this.account.referrer) {
                headers.append(CATSBEY_REFERRER_HEADER, this.account.referrer);
            }
            for (const address of this.account.addresses) {
                headers.append(CATSBEY_ADDRESS_HEADER, address);
            }
            response = await fetch(this.url, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    query,
                    variables: vars,
                }),
            });
            body = response.status === 200 ? await response.json() : undefined;
        } catch (e: any) {
            throw new ApiError(e.message, KnownApiErrorCode.ServiceError);
        }
        if (body) {
            if (body.data !== null && body.data !== undefined) {
                return body.data;
            }
            if (Array.isArray(body.errors) && body.errors.length > 0) {
                const err = body.errors[0];
                let code = String(err.extensions?.code ?? KnownApiErrorCode.ServiceError);
                throw new ApiError(err.message, code);
            }
        }
        throw new ApiError(await response.text(), String(response.status));
    }

    async getPools(): Promise<ApiPool> {
        return await this.queryApi(
            `
            query {
                pools {
                    name
                    account
                    ticketPrice
                    ticketCount
                    bidCount
                }
            }
        `,
            {},
        );
    }
}

export class ApiError extends Error {
    code: ApiErrorCode;

    constructor(msg: string, code: ApiErrorCode) {
        super(msg);
        this.code = code;
    }
}

const ApiContext = React.createContext<Api>(new Api());

export function useApi(): Api {
    return useContext(ApiContext);
}
