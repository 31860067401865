import { useEffect, useState } from "react";

import { useTheme } from "config";
import { ApiAccount, useApi } from "api";
import { listenAccount } from "utils";
import { LanguageMenu } from "./LanguageMenu";
import { Col, Container, Row } from "react-bootstrap";
import { useLocalization } from "../config/localization";

export function AppHeader() {
    const loc = useLocalization().app;
    const api = useApi();
    const [account, setAccount] = useState<ApiAccount>(api.account);
    const theme = useTheme();

    useEffect(() => {
        return listenAccount(api, account => {
            setAccount(account);
        });
    }, [api]);
    return (
        <Container className={"p-0 appHeader"} fluid>
            <Row className={"m-0 mt-2 mb-2 justify-content-between align-items-center"}>
                <Col md={"2"} sm={"2"} xs={"2"}>
                    <img
                        alt="Info"
                        src={theme.info}
                        width="24"
                        height="24"
                    />
                </Col>
                <Col md={"auto"} sm={"auto"} xs={"auto"} className={"p-0 align-items-center"}>
                    <Container className={"p-0 m-0"}>
                        <Row className={"m-0 align-items-center gap-2"}>
                            <Col className={"p-0"}>
                                <img
                                    alt="Logo"
                                    src={theme.logo}
                                    width="40"
                                    height="40"
                                />
                            </Col>
                            <Col className={"p-0"}>
                    <span style={{
                        color: "white",
                        fontSize: "32",
                        fontWeight: "bold",
                    }}>{loc.header}</span>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={"auto"} sm={"auto"} xs={"auto"} className={"p-0"}>
                    <LanguageMenu />
                </Col>
            </Row>
            <Row className={"appHeaderBottomLine mt-1"}></Row>
        </Container>
    );
}
