import React, { useContext } from "react";
import { Theme, Themes } from "./theme";
import { Localization, Localizations } from "./localization";

export { NAV } from "./nav";

type Config = {
    lang: string; theme: string;
};

const defaultConfig: Config = {
    lang: "en",
    theme: "dark",
};

const ConfigContext = React.createContext<Config>(defaultConfig);

function loadConfig(): Config {
    try {
        const config = window.localStorage.getItem("CTS_CONFIG");
        return config ? JSON.parse(config) : defaultConfig;
    } catch {
        return defaultConfig;
    }
}

function saveConfig(config: Config) {
    return window.localStorage.setItem("CTS_CONFIG", JSON.stringify(config));
}

export function useTheme(): Theme {
    const config = useContext(ConfigContext);
    return Themes[config.theme];
}

