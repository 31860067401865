import { useEffect, useState } from "react";
import { ApiAccount, useApi } from "api";
import { listenAccount } from "utils";

import { AppHeader } from "../../components";
import { Image, Stack, Tab, Tabs } from "react-bootstrap";
import { useLocalization } from "../../config/localization";


export function Main() {
    const loc = useLocalization().main;
    const api = useApi();
    const [account, setAccount] = useState<ApiAccount>(api.account);
    useEffect(() => {
        return listenAccount(api, account => {
            setAccount(account);
        });
    }, [api, account]);

    return (
        <Stack direction={"vertical"}>
            <AppHeader />
            <Tabs
                defaultActiveKey="profile"
                transition={false}
                variant={"pills"}
                className={"justify-content-center"}

            >
                <Tab id={"referral"} eventKey="referral" title>
                    Tab content for Home
                </Tab>
                <Tab id={"user"} eventKey="user" title>
                    Tab content for Profile
                </Tab>
                <Tab id={"my_pools"} eventKey="my_pools" title>
                    Tab content for Contact
                </Tab>
            </Tabs>

            <div style={{ height: 500 }}>content</div>
            <Stack direction={"horizontal"}>
                <div>{loc.copyright}</div>
            </Stack>
        </Stack>
    );
}
