import { EN, Localization } from "./en";
import { RU } from "./ru";
import { createContext, ReactNode, useContext, useState } from "react";

export type { Localization } from "./en";

export const Localizations: { [lang: string]: Localization } = {
    en: EN,
    ru: RU,
};


interface LanguageValue {
    language: string,
    setLanguage: (language: string) => void
}

function getLanguage(): string {
    return (
        window.localStorage.getItem("CTS_LANGUAGE") ?? navigator.language ?? "en"
    ).split("-")[0];
}

function setLanguage(value: string) {
    window.localStorage.setItem("CTS_LANGUAGE", value);
}


const LanguageContext = createContext({
    language: getLanguage(),
    setLanguage,
} as LanguageValue);


export function LanguageProvider({ children }: { children: ReactNode }) {
    const [language, setLanguage] = useState(getLanguage());
    const value = {
        language,
        setLanguage(language: string) {
            window.localStorage.setItem("CTS_LANGUAGE", language);
            setLanguage(language);
        },
    };

    return (
        <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
    );
}

export function useLanguage(): LanguageValue {
    return useContext(LanguageContext);
}

export function useLocalization(): Localization {
    return Localizations[useContext(LanguageContext).language] ?? EN;
}
