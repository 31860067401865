const DARK = {
    name: "dark",
    logo: "/logo-lt.svg",
    info: "/info-lt.svg",
    lang: "/lang-lt.svg",
    user: "/user-lt.svg",
    user_selected: "/user-dk.svg",
    referral: "/referral-lt.svg",
    referral_selected: "/referral-dk.svg",
    my_pools: "/my_pools-lt.svg",
    my_pools_selected: "/my_pools-dk.svg",
    symbol: "🌛",
};

export type Theme = typeof DARK;

const LIGHT: Theme = {
    name: "light",
    logo: "/logo-light.svg",
    info: "/info-dark.svg",
    lang: "/lang-dark.svg",
    user: "/user-light.svg",
    user_selected: "/user-dark.svg",
    referral: "/referral-light.svg",
    referral_selected: "/referral-light.svg",
    my_pools: "/my_pools-dk.svg",
    my_pools_selected: "/my_pools-lt.svg",
    symbol: "🌞",
};

export const Themes: { [name: string]: Theme } = {
    dark: DARK,
    light: LIGHT,
};
