import { Api, ApiAccount } from "./api";
import buffer from "buffer";

export type AsyncGuard = {
    processing: boolean;
};

export function asyncGuard(): AsyncGuard {
    return { processing: false };
}

export function asyncWork(
    guard: AsyncGuard,
    work: () => Promise<void>,
    setGuard: (guard: AsyncGuard) => void,
    setError: (error: string) => void,
): () => Promise<void> {
    return async () => {
        if (guard.processing) {
            return;
        }
        guard.processing = true;
        setGuard(guard);
        try {
            await work();
        } catch (e: any) {
            setError(e.message);
        }
        guard.processing = false;
        setGuard(guard);
    };
}

export function asyncSubmit(
    work: () => Promise<void>,
    setSubmitting: (isSubmitting: boolean) => void,
    setError: (error: string | undefined) => void,
): () => Promise<void> {
    return async () => {
        setSubmitting(true);
        try {
            setError(undefined);
            await work();
        } catch (e: any) {
            setError(e.message);
        }
        setSubmitting(false);
    };
}

export function listenAccount(api: Api, on?: (account: ApiAccount) => void) {
    const handler = api.on({
        account(account) {
            on?.(account);
        },
    });
    return () => api.remove(handler);
}

export function basicAuth(secret: string): string {
    return `Basic ${buffer.Buffer.from(`:${secret}`, "utf8").toString("base64")}`;
}

/*
// Setup:

let color1 = "rgb(20,60,200)";
let color2 = "rgba(20,60,200,0.67423)";
let color3 = "#67DAF0";
let color4 = "#5567DAF0";
let color5 = "#F3A";
let color6 = "#F3A9";
let color7 = "rgb(200,60,20)";
let color8 = "rgba(200,60,20,0.98631)";

// Tests:

// Log Blending
// Shade (Lighten or Darken)
pSBC ( 0.42, color1 ); // rgb(20,60,200) + [42% Lighter] => rgb(166,171,225)
pSBC ( -0.4, color5 ); // #F3A + [40% Darker] => #c62884
pSBC ( 0.42, color8 ); // rgba(200,60,20,0.98631) + [42% Lighter] => rgba(225,171,166,0.98631)

// Shade with Conversion (use "c" as your "to" color)
pSBC ( 0.42, color2, "c" ); // rgba(20,60,200,0.67423) + [42% Lighter] + [Convert] => #a6abe1ac

// RGB2Hex & Hex2RGB Conversion Only (set percentage to zero)
pSBC ( 0, color6, "c" ); // #F3A9 + [Convert] => rgba(255,51,170,0.6)

// Blending
pSBC ( -0.5, color2, color8 ); // rgba(20,60,200,0.67423) + rgba(200,60,20,0.98631) + [50% Blend] => rgba(142,60,142,0.83)
pSBC ( 0.7, color2, color7 ); // rgba(20,60,200,0.67423) + rgb(200,60,20) + [70% Blend] => rgba(168,60,111,0.67423)
pSBC ( 0.25, color3, color7 ); // #67DAF0 + rgb(200,60,20) + [25% Blend] => rgb(134,191,208)
pSBC ( 0.75, color7, color3 ); // rgb(200,60,20) + #67DAF0 + [75% Blend] => #86bfd0

// Linear Blending

// Shade (Lighten or Darken)
pSBC ( 0.42, color1, false, true ); // rgb(20,60,200) + [42% Lighter] => rgb(119,142,223)
pSBC ( -0.4, color5, false, true ); // #F3A + [40% Darker] => #991f66
pSBC ( 0.42, color8, false, true ); // rgba(200,60,20,0.98631) + [42% Lighter] => rgba(223,142,119,0.98631)

// Shade with Conversion (use "c" as your "to" color)
pSBC (0.42, color2, "c", true ); // rgba(20,60,200,0.67423) + [42% Lighter] + [Convert] => #778edfac

// RGB2Hex & Hex2RGB Conversion Only (set percentage to zero)
pSBC (0, color6, "c", true ); // #F3A9 + [Convert] => rgba(255,51,170,0.6)

// Blending
pSBC ( -0.5, color2, color8, true ); // rgba(20,60,200,0.67423) + rgba(200,60,20,0.98631) + [50% Blend] => rgba(110,60,110,0.83)
pSBC ( 0.7, color2, color7, true ); // rgba(20,60,200,0.67423) + rgb(200,60,20) + [70% Blend] => rgba(146,60,74,0.67423)
pSBC ( 0.25, color3, color7, true ); // #67DAF0 + rgb(200,60,20) + [25% Blend] => rgb(127,179,185)
pSBC ( 0.75, color7, color3, true ); // rgb(200,60,20) + #67DAF0 + [75% Blend] => #7fb3b9

// *** Other Stuff ***
// Error Checking
pSBC ( 0.42, "#FFBAA" ); // #FFBAA + [42% Lighter] => null(Invalid Input Color)
pSBC ( 42, color1, color5 ); // rgb(20,60,200) + #F3A + [4200% Blend] => null(Invalid Percentage Range)
pSBC ( 0.42, {} ); // [object Object] + [42% Lighter] => null(Strings Only for Color)
pSBC ( "42", color1 ); // rgb(20,60,200) + ["42"] => null(Numbers Only for Percentage)
pSBC ( 0.42, "salt" ); // salt + [42% Lighter] => null(A Little Salt is No Good...)

// Error Check Fails (Some Errors are not Caught)
pSBC ( 0.42, "#salt" ); // #salt + [42% Lighter] => #a5a5a500(...and a Pound of Salt is Jibberish)

// Ripping
pSBCr ( color4 ); // #5567DAF0 + [Rip] => [object Object] => {'r':85,'g':103,'b':218,'a':0.941}
 */

function pSBCr(d: any) {
    let n = d.length, x = {} as any;
    if (n > 9) {
        const [r, g, b, a] = (
            d = d.split(",")
        );
        n = d.length;
        if (n < 3 || n > 4) {
            return null;
        }
        x.r = parseInt(r[3] === "a" ? r.slice(5) : r.slice(4));
        x.g = parseInt(g);
        x.b = parseInt(b);
        x.a = a ? parseFloat(a) : -1;
    } else {
        if (n === 8 || n === 6 || n < 4) {
            return null;
        }
        if (n < 6) {
            d =
                "#" +
                d[1] +
                d[1] +
                d[2] +
                d[2] +
                d[3] +
                d[3] +
                (
                    n > 4 ? d[4] + d[4] : ""
                );
        }
        d = parseInt(d.slice(1), 16);
        if (n === 9 || n === 5) {
            x.r =
                (
                    d >> 24
                ) & 255;
            x.g =
                (
                    d >> 16
                ) & 255;
            x.b =
                (
                    d >> 8
                ) & 255;
            x.a =
                Math.round((
                    d & 255
                ) / 0.255) / 1000;
        } else {
            x.r = d >> 16;
            x.g =
                (
                    d >> 8
                ) & 255;
            x.b = d & 255;
            x.a = -1;
        }
    }
    return x;
}

export const recolor = (p: number, c0: string, c1?: any, l?: any): string => {
    c0 =
        (
            webColors as any
        )[c0.toLowerCase()] ?? c0;
    let r: any, g: any, b: any, P: any, f: any, t: any, h: any, a: any = typeof c1 === "string";
    if (p <
        -1 ||
        p >
        1 ||
        (
            c0[0] !== "r" && c0[0] !== "#"
        ) ||
        (
            !!c1 && !a
        )) {
        return "";
    }
    h = c0.length > 9;
    h =
        a ? (
            c1.length > 9 ? true : c1 === "c" ? !h : false
        ) : h;
    f = pSBCr(c0);
    P = p < 0;
    t = !!c1 && c1 !== "c" ? pSBCr(c1) : P ? {
        r: 0, g: 0, b: 0, a: -1,
    } : {
        r: 255, g: 255, b: 255, a: -1,
    };
    p = P ? p * -1 : p;
    P = 1 - p;
    if (!f || !t) {
        return "";
    }
    if (l) {
        r = Math.round(P * f.r + p * t.r);
        g = Math.round(P * f.g + p * t.g);
        b = Math.round(P * f.b + p * t.b);
    } else {
        r =
            Math.round((
                P * f.r ** 2 + p * t.r ** 2
            ) ** 0.5);
        g =
            Math.round((
                P * f.g ** 2 + p * t.g ** 2
            ) ** 0.5);
        b =
            Math.round((
                P * f.b ** 2 + p * t.b ** 2
            ) ** 0.5);
    }
    a = f.a;
    t = t.a;
    f = a >= 0 || t >= 0;
    a =
        f ? (
            a < 0 ? t : t < 0 ? a : a * P + t * p
        ) : 0;
    if (h) {
        return (
            "rgb" +
            (
                f ? "a(" : "("
            ) +
            r +
            "," +
            g +
            "," +
            b +
            (
                f ? "," + Math.round(a * 1000) / 1000 : ""
            ) +
            ")"
        );
    } else {
        return (
            "#" +
            (
                4294967296 +
                r *
                16777216 +
                g *
                65536 +
                b *
                256 +
                (
                    f ? Math.round(a * 255) : 0
                )
            )
                .toString(16)
                .slice(1, f ? undefined : -2)
        );
    }
};

const webColors = {
    mediumvioletred: "#c71585",
    deeppink: "#ff1493",
    palevioletred: "#db7093",
    hotpink: "#ff69b4",
    lightpink: "#ffb6c1",
    pink: "#ffc0cb",
    darkred: "#8b0000",
    red: "#ff0000",
    firebrick: "#b22222",
    crimson: "#dc143c",
    indianred: "#cd5c5c",
    lightcoral: "#f08080",
    salmon: "#fa8072",
    darksalmon: "#e9967a",
    lightsalmon: "#ffa07a",
    orangered: "#ff4500",
    tomato: "#ff6347",
    darkorange: "#ff8c00",
    coral: "#ff7f50",
    orange: "#ffa500",
    darkkhaki: "#bdb76b",
    gold: "#ffd700",
    khaki: "#f0e68c",
    peachpuff: "#ffdab9",
    yellow: "#ffff00",
    palegoldenrod: "#eee8aa",
    moccasin: "#ffe4b5",
    papayawhip: "#ffefd5",
    lightgoldenrodyellow: "#fafad2",
    lemonchiffon: "#fffacd",
    lightyellow: "#ffffe0",
    maroon: "#800000",
    brown: "#a52a2a",
    saddlebrown: "#8b4513",
    sienna: "#a0522d",
    chocolate: "#d2691e",
    darkgoldenrod: "#b8860b",
    peru: "#cd853f",
    rosybrown: "#bc8f8f",
    goldenrod: "#daa520",
    sandybrown: "#f4a460",
    tan: "#d2b48c",
    burlywood: "#deb887",
    wheat: "#f5deb3",
    navajowhite: "#ffdead",
    bisque: "#ffe4c4",
    blanchedalmond: "#ffebcd",
    cornsilk: "#fff8dc",
    darkgreen: "#006400",
    green: "#008000",
    darkolivegreen: "#556b2f",
    forestgreen: "#228b22",
    seagreen: "#2e8b57",
    olive: "#808000",
    olivedrab: "#6b8e23",
    mediumseagreen: "#3cb371",
    limegreen: "#32cd32",
    lime: "#00ff00",
    springgreen: "#00ff7f",
    mediumspringgreen: "#00fa9a",
    darkseagreen: "#8fbc8f",
    mediumaquamarine: "#66cdaa",
    yellowgreen: "#9acd32",
    lawngreen: "#7cfc00",
    chartreuse: "#7fff00",
    lightgreen: "#90ee90",
    greenyellow: "#adff2f",
    palegreen: "#98fb98",
    teal: "#008080",
    darkcyan: "#008b8b",
    lightseagreen: "#20b2aa",
    cadetblue: "#5f9ea0",
    darkturquoise: "#00ced1",
    mediumturquoise: "#48d1cc",
    turquoise: "#40e0d0",
    aqua: "#00ffff",
    cyan: "#00ffff",
    aquamarine: "#7fffd4",
    paleturquoise: "#afeeee",
    lightcyan: "#e0ffff",
    midnightblue: "#191970",
    navy: "#000080",
    darkblue: "#00008b",
    mediumblue: "#0000cd",
    blue: "#0000ff",
    royalblue: "#4169e1",
    steelblue: "#4682b4",
    dodgerblue: "#1e90ff",
    deepskyblue: "#00bfff",
    cornflowerblue: "#6495ed",
    skyblue: "#87ceeb",
    lightskyblue: "#87cefa",
    lightsteelblue: "#b0c4de",
    lightblue: "#add8e6",
    powderblue: "#b0e0e6",
    indigo: "#4b0082",
    purple: "#800080",
    darkmagenta: "#8b008b",
    darkviolet: "#9400d3",
    darkslateblue: "#483d8b",
    blueviolet: "#8a2be2",
    darkorchid: "#9932cc",
    fuchsia: "#ff00ff",
    magenta: "#ff00ff",
    slateblue: "#6a5acd",
    mediumslateblue: "#7b68ee",
    mediumorchid: "#ba55d3",
    mediumpurple: "#9370db",
    orchid: "#da70d6",
    violet: "#ee82ee",
    plum: "#dda0dd",
    thistle: "#d8bfd8",
    lavender: "#e6e6fa",
    black: "#000000",
    darkslategray: "#2f4f4f",
    dimgray: "#696969",
    slategray: "#708090",
    gray: "#808080",
    lightslategray: "#778899",
    darkgray: "#a9a9a9",
    silver: "#c0c0c0",
    lightgray: "#d3d3d3",
    gainsboro: "#dcdcdc",
};
