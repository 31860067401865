import { Localization } from "./en";

export const RU: Localization = {
    name: "Русский",
    date: {
        months: {
            January: "Январь",
            February: "Февраль",
            March: "Март",
            April: "Апрель",
            May: "Май",
            June: "Июнь",
            July: "Июль",
            August: "Август",
            September: "Сентябрь",
            October: "Октябрь",
            November: "Ноябрь",
            December: "Декабрь",
        },
    },
    validation: {
        required: "Отсутствует обязательное значение",
    },
    main: {
        noPools: "Нет пулов...",
        loadFailed: "Не удалось загрузить учетную запись",
        copyright: "© Авторские права 2024 Catsbey",
    },
    app: {
        header: "Catsbey",
        main: "Главная",
    },
    forms: {
        select: "Выберите...",
    },
    props: {
        copyToClipboard: "Скопировать в буфер обмена",
    },
    errors: {
        UNAUTHORISED: "Не авторизован",
        INTERNAL_SERVER_ERROR: "Внутренняя ошибка сервера",
        INVALID_USER_DATA: "Неверные данные",
        LIMIT_REACHED: "Достигнут лимит",
    },
};
