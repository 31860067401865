import { Dropdown } from "react-bootstrap";
import "./LanguageMenu.css";
import { Localizations, useLanguage } from "../config/localization";

export function LanguageMenu() {
    const language = useLanguage();
    const menu = Object.entries(Localizations)
        .map(([lang, localization]) => {
            const sel = language.language === lang ? <>&nbsp;&nbsp;&nbsp;✓</> : "";
            return <Dropdown.Item key={lang} eventKey={lang}>{localization.name}{sel}</Dropdown.Item>;
        });
    const onSetLang = (eventKey: any, _event: Object) => {
        language.setLanguage(eventKey);
    };
    return (
        <Dropdown onSelect={onSetLang}>
            <Dropdown.Toggle id="dropdown-autoclose-true" className={"cts-language-button"}>
                <img src={"/lang-lt.svg"} width={24} height={24} alt="Language"></img>
            </Dropdown.Toggle>
            <Dropdown.Menu>{menu}</Dropdown.Menu>
        </Dropdown>
    );
}

/*

export function AppHeader() {
    const { setLanguage } = useLanguage();
    const loc = useLoc().app;
    const api = useApi();
    const [account, setAccount] = useState<ApiAccount>(api.account);
    const theme = useTheme();

    useEffect(() => {
        return listenAccount(api, account => {
            setAccount(account);
        });
    }, [api]);
    const [langAnchor, setLangAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(langAnchor);
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} className={"pt-2 pb-2"}>
            <div></div>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <img
                    alt="Logo"
                    src={theme.logo}
                    width="40"
                    height="40"
                />
                <span className="d-inline-block align-center" style={{
                    fontSize: "32",
                    fontWeight: "bold",
                }}>{loc.header}</span>
            </Stack>
            <div>
                <IconButton
                    id="basic-button"
                    size={"small"}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                        setLangAnchor(event.currentTarget);
                    }}
                >
                    <img src={theme.lang} width={24} height={24} alt="Language"></img>
                </IconButton>
                <CtsMenu
                    open={open}
                    anchorEl={langAnchor}
                    id="basic-menu"
                    onClose={() => setLangAnchor(null)}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <CtsMenuItem onClick={() => setLanguage("en")}>English&nbsp;&nbsp;&nbsp;✓</CtsMenuItem>
                    <CtsMenuItem onClick={() => setLanguage("ru")}>Русский</CtsMenuItem>
                </CtsMenu>
            </div>
        </Stack>
    );
}
*/
