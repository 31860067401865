import { BrowserRouter, Route, Routes } from "react-router-dom";

import { NAV } from "config";
import "./App.css";
import { Main } from "./screens";
import { LanguageProvider } from "./config/localization";

const metaTag = document.getElementsByName("project-version")[0];
// @ts-ignore
metaTag.content = process.env.REACT_APP_VERSION;

function App() {
    return (
        <LanguageProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={NAV.root} element={<Main />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
}

export default App;
