export const EN = {
    name: "English",
    date: {
        months: {
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December",
        },
    },
    validation: {
        required: "Missing required value",
    },
    main: {
        noPools: "No pools...",
        loadFailed: "Failed to load account data",
        copyright: "© Copyright 2024 Catsbey",
    },
    app: {
        header: "Catsbey",
        main: "Main",
    },
    forms: {
        select: "Select...",
    },
    props: {
        copyToClipboard: "Copy to clipboard",
    },
    errors: {
        UNAUTHORISED: "Unauthorised",
        INTERNAL_SERVER_ERROR: "Internal server error",
        INVALID_USER_DATA: "Invalid user data",
        LIMIT_REACHED: "Limit reached",
    },
};

export type Localization = typeof EN;
